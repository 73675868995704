<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <!--    <v-breadcrumbs :items="items" class="pb-0">-->
    <!--      <template v-slot:divider>-->
    <!--        <v-icon>mdi-chevron-right</v-icon>-->
    <!--      </template>-->
    <!--    </v-breadcrumbs>-->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <AllSell />
      </v-tab-item>

      <!--      <v-tab-item>-->
      <!--        <ChannelSell />-->
      <!--      </v-tab-item>-->
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mdiCartOutline, mdiCartPlus, mdiMore } from '@mdi/js'
import { ref } from '@vue/composition-api'

// demos
import { mdiAbacus } from '@mdi/js/commonjs/mdi'
import AllSell from '@/views/pages/report/tabs/allSell'
import ChannelSell from '@/views/pages/report/tabs/channelSell'

export default {
  components: {
    AllSell,
    ChannelSell,
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'ทั้งหมด', icon: mdiCartOutline },

      // { title: 'ตามช่องทางการขาย', icon: mdiCartPlus },
    ]

    return {
      tab,
      tabs,
      icons: {
        mdiCartOutline,
        mdiCartPlus,
        mdiMore,
      },
    }
  },
  data: () => ({
    member_id: '',

    // items: [
    //   {
    //     text: 'สินค้า',
    //     disabled: false,
    //     href: 'product',
    //   },
    //   {
    //     text: 'สต๊อก',
    //     disabled: true,
    //     href: 'stock',
    //   },
    // ],
  }),
  mounted() {
    this.member_id = this.$route.params.id

    // this.get_order()
  },
  methods: {
    // get_order() {
    //
    // },
  },
}
</script>
