var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-card',{staticStyle:{"min-height":"180px"}},[_c('v-card-text',[_c('br'),_c('h3',{staticClass:"text-center"},[_vm._v(" ยอดช่องทางการขาย ")]),_c('br'),_c('h1',{staticClass:"text-center"},[_vm._v(" 1,000,000 ")])])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-card',{staticStyle:{"min-height":"180px"}},[_c('v-card-text',[_c('br'),_c('h3',[_vm._v(" ยอดขาย ")]),_c('br'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.header2,"items":_vm.orders,"search":_vm.search,"server-items-length":_vm.total,"options":_vm.serverOptions,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:options":function($event){_vm.serverOptions=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(((_vm.serverOptions.page - 1) * _vm.serverOptions.itemsPerPage) + index + 1)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}},{key:"no-data",fn:function(){return undefined},proxy:true}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.orders,"search":_vm.search,"server-items-length":_vm.total,"options":_vm.serverOptions,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:options":function($event){_vm.serverOptions=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('DatePicker',{staticStyle:{"width":"100%"},attrs:{"range":"","multi-calendars":"","value-type":"format","format":"YYYY-MM-DD","placeholder":"เลือกเฉพาะวันที่"},on:{"change":function($event){return _vm.getOrderReport()}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)],1)]},proxy:true},{key:"item.index",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(((_vm.serverOptions.page - 1) * _vm.serverOptions.itemsPerPage) + index + 1)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}},{key:"no-data",fn:function(){return undefined},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }