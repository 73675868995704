<template>
  <v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="6"
      >
        <v-card style="min-height: 180px;">
          <v-card-text>
            <br>
            <h3
              class="text-center"
            >
              ยอดช่องทางการขาย
            </h3>
            <br>

            <h1 class="text-center">
              1,000,000
            </h1>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
      >
        <v-card style="min-height: 180px;">
          <v-card-text>
            <br>
            <h3>
              ยอดขาย
            </h3>
            <br>
            <v-data-table
              :headers="header2"
              :items="orders"
              :search="search"
              :server-items-length="total"
              :options.sync="serverOptions"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              class="elevation-1"
            >
              <template v-slot:item.index="{ index }">
                {{ ((serverOptions.page - 1) * serverOptions.itemsPerPage) + index + 1 }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                >
                  {{ icons.mdiPencil }}
                </v-icon>
                <v-icon
                  small
                  @click="deleteItem(item)"
                >
                  {{ icons.mdiDelete }}
                </v-icon>
              </template>
              <template v-slot:no-data>
                <!-- <v-btn
                  color="primary"
                  @click="initialize"
                >
                  Reset
                </v-btn> -->
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="orders"
            :search="search"
            :server-items-length="total"
            :options.sync="serverOptions"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <!-- <v-toolbar-title> -->
                <!-- </v-toolbar-title> -->
                <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
                <!--        <v-spacer></v-spacer>-->
                <v-row>
                  <v-col cols="5">
                    <v-text-field
                      v-model="search"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="5">
                    <DatePicker
                      v-model="date"
                      range
                      multi-calendars
                      value-type="format"
                      format="YYYY-MM-DD"
                      placeholder="เลือกเฉพาะวันที่"
                      style="width: 100%"
                      @change="getOrderReport()"
                    ></DatePicker>
                  </v-col>
                  <!--                <v-col cols="2">-->
                  <!--                  <v-btn-->
                  <!--                    color="primary"-->
                  <!--                    dark-->
                  <!--                    class="mb-2"-->
                  <!--                    @click="print"-->
                  <!--                  >-->
                  <!--                    พิมพ์รายการสั่งซื้อ-->
                  <!--                  </v-btn>-->
                  <!--                </v-col>-->
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:item.index="{ index }">
              {{ ((serverOptions.page - 1) * serverOptions.itemsPerPage) + index + 1 }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                {{ icons.mdiPencil }}
              </v-icon>
              <v-icon
                small
                @click="deleteItem(item)"
              >
                {{ icons.mdiDelete }}
              </v-icon>
            </template>
            <template v-slot:no-data>
              <!-- <v-btn
                color="primary"
                @click="initialize"
              >
                Reset
              </v-btn> -->
            </template>
          </v-data-table>
        </v-card-text>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import { mdiPencil, mdiDelete } from '@mdi/js'
import { Bar, Pie, Line as LineChartGenerator } from 'vue-chartjs/legacy'

// import config from '@/config'
import DatePicker from 'vue2-datepicker'
import Swal from 'sweetalert2'
import instance from '@/axios.service'
import instance_member_movement from '@/services/memberMovement'

export default {
  components: {
    DatePicker,
    Swal,
    Bar,
    Pie,
    LineChartGenerator,
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {
      },
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    orders: [],
    dialog: false,
    dialogDelete: false,
    serverOptions: {
      page: 1,
      itemsPerPage: 10,

    },
    date: '',
    total: 0,
    search: '',
    sortBy: '',
    sortDesc: '',
    headers: [
      {
        text: '#',
        value: 'index',
        sortable: false,
      },
      {
        text: 'รหัสสินค้า',
        value: 'code',
        sortable: false,
      },
      {
        text: 'สินค้า',
        value: 'product_name',
        sortable: false,
      },
      {
        text: 'จำนวน',
        value: 'amount',
        sortable: false,
      },
      {
        text: 'ยอดขาย (บาท)',
        value: 'sum_product_amount',
        sortable: false,
      },
      {
        text: 'ยอดขาย (%)',
        value: 'cost',
        sortable: false,
      },
    ],
    header2: [
      {
        text: 'ช่องทางการขาย',
        value: 'code',
        sortable: false,
      },
      {
        text: 'จำนวนรายการ',
        value: 'product_name',
        sortable: false,
      },
      {
        text: 'ยอดขาย (บาท)',
        value: 'sum_product_amount',
        sortable: false,
      },
    ],
    chartData: {
      labels: ['ลูกค้าใหม่', 'ลูกค้าซื้อซ้ำ'],
      datasets: [
        {
          label: 'ลูกค้าใหม่',
          backgroundColor: ['#f87979', '#3E80E1'],
          data: [40, 80],
        },
      ],

    },
    chartOptions: {
      responsive: true,
    },
    editedIndex: -1,
    editedItem: {
      numberCustomer: '',
      status: '',
      numberTax: null,
      nameCustomer: null,
      credit: null,
      dateBill: '',
      dateGetMoney: '',
    },
    defaultItem: {
      numberCustomer: '',
      status: '',
      numberTax: null,
      nameCustomer: null,
      credit: null,
      dateBill: '',
      dateGetMoney: '',
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม กลุ่มพนักงาน' : 'แก้ไข กลุ่มพนักงาน'
    },
  },

  watch: {
    serverOptions: {
      handler() {
        this.getOrderReport()
      },
    },
    search: {
      handler() {
        this.getOrderReport()
      },
    },

    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    this.getOrderReport()
  },

  methods: {
    print() {
      let url = `/print_member_order/${this.member_id}`
      if (this.date) {
        url += `?date=${this.date[0]},${this.date[1]}`
      }
      window.open(url, '_blank', 'noreferrer')
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem)
      } else {
        this.desserts.push(this.editedItem)
      }
      this.close()
    },
    getOrderReport() {
      const data = {
        member_id: this.member_id,
        search: this.search,
        page: this.serverOptions.page,
        size: this.serverOptions.itemsPerPage,
        sortBy: this.sortBy ?? '',
        sortDesc: this.sortDesc ?? '',
        date: this.date,
      }
      this.$store.state.isLoading = true
      instance_member_movement.getOrder(data)
        .then(res => {
          this.orders = res.data.data.data
          this.total = res.data.data.total
          this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
          this.serverOptions.page = res.data.data.current_page
          this.$store.state.isLoading = false
        })
        .catch(err => {
          this.$store.state.isLoading = false
        })
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
