<template>
  <v-row>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-card>
          <v-card-text>
            <br>
            <h2>เลือกวันที่</h2>
            <DatePicker
              v-model="date"
              type="date"
              range
              multi-calendars
              value-type="format"
              format="YYYY-MM-DD"
              placeholder="ช่วงเวลา"
              @change="changeDate"
            ></DatePicker>
          </v-card-text>
        </v-card>
      </v-col>
      <br>
      <v-col
        cols="12"
        sm="6"
        md="6"
      >
        <v-card style="min-height: 180px;">
          <v-card-text>
            <br>
            <!--            <h3-->
            <!--              class="text-center"-->
            <!--            >-->
            <!--              สรุปยอดซื้อ-->
            <!--            </h3>-->

            <!--            <v-select-->
            <!--              outlined-->
            <!--              dense-->
            <!--              hide-details-->
            <!--              label="กรุณาเลือก"-->
            <!--              :items="['ยอดขายรวม', 'กำไรจากการขาย', 'กำไรรวม']"-->
            <!--              class="selected"-->
            <!--            ></v-select>-->

            <v-card-text>
              <h3>
                {{ titleGrapht1[typeGraphSelected] }}
              </h3>
              <v-select
                v-model="typeGraphSelected"
                outlined
                dense
                hide-details
                :items="typeGraph1"
                item-text="name"
                item-value="value"
                class="selected"
              ></v-select>
            </v-card-text>
            <br>
            <h1 class="text-center">
              {{ total_sell }}
            </h1>
            <v-btn
              class="text-center"
              @click="download_excel(1)"
            >
              Download Excel
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
      >
        <v-card style="min-height: 180px">
          <v-card-text>
            <h3>
              {{ titleGrapht2[typeGraph2Selected] }}
            </h3>
            <v-select
              v-model="typeGraph2Selected"
              outlined
              dense
              hide-details
              :items="typeGraph2"
              item-text="name"
              item-value="value"
              class="selected"
            ></v-select>
          </v-card-text>
          <!--          <v-select-->
          <!--            outlined-->
          <!--            dense-->
          <!--            hide-details-->
          <!--            label="กรุณาเลือก"-->
          <!--            :items="['สินค้า', 'หมวดหมู่']"-->
          <!--            class="selected"-->
          <!--          ></v-select>-->
          <v-card-text>
            <br>
            <LineChartGenerator
              :chart-options="chartOptions"
              :chart-data="chartData"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="width"
              :height="height"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-card-text>
          <!--          <h1>{{ titleGrapht3[typeGraph3Selected] }}</h1>-->
          <!--          <v-select-->
          <!--            v-model="typeGraph3Selected"-->
          <!--            outlined-->
          <!--            dense-->
          <!--            hide-details-->
          <!--            :items="typeGraph3"-->
          <!--            item-text="name"-->
          <!--            item-value="value"-->
          <!--            class="selected"-->
          <!--          ></v-select>-->
          <v-data-table
            :headers="headers"
            :items="orders"
            :search="search"
            :server-items-length="total"
            :options.sync="serverOptions"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <!-- <v-toolbar-title> -->
                <!-- </v-toolbar-title> -->
                <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
                <!--        <v-spacer></v-spacer>-->
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model="search"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      v-model="typeGraph3Selected"
                      outlined
                      dense
                      hide-details
                      label="กรุณาเลือก"
                      :items="titleGrapht3"
                      item-text="name"
                      item-value="value"
                      class="selected"
                    ></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                      class="text-center"
                      @click="download_excel(2)"
                    >
                      Download Excel
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:item.index="{ index }">
              {{ ((serverOptions.page - 1) * serverOptions.itemsPerPage) + index + 1 }}
            </template>
            <template v-slot:item.sell_percent="{ item }">
              {{ ( total_sell ? ((item.order_expense/total_sell)*100) : 0) }}
            </template>
            <template v-slot:no-data>
              <!-- <v-btn
                color="primary"
                @click="initialize"
              >
                Reset
              </v-btn> -->
            </template>
          </v-data-table>
        </v-card-text>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import { mdiPencil, mdiDelete } from '@mdi/js'
import Swal from 'sweetalert2'
import { Bar, Pie, Line as LineChartGenerator } from 'vue-chartjs/legacy'

// import config from '@/config'
import DatePicker from 'vue2-datepicker'
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend, LinearScale, LineElement, PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import instance from '@/axios.service'
import instance_sell from '@/services/order'

ChartJS.register(Title, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement)

export default {
  components: {
    DatePicker,
    Swal,
    Bar,
    Pie,
    LineChartGenerator,
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {
      },
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    orders: [],
    total_sell: 0,
    dialog: false,
    dialogDelete: false,
    serverOptions: {
      page: 1,
      itemsPerPage: 10,

    },
    date: [],
    total: 0,
    search: '',
    sortBy: '',
    sortDesc: '',
    typeGraphSelected: 1,
    titleGrapht1: {
      1: 'ยอดขายรวม',
      2: 'กำไรจากการขาย',
      3: 'ยอดขายตามหมวดหมู่',
    },
    typeGraph1: [
      {
        value: 1,
        name: 'ยอดขายรวม',
      },
      {
        value: 2,
        name: 'กำไรจากการขาย',
      },

      // {
      //   value: 3,
      //   name: 'ยอดขายตามหมวดหมู่',
      // },
    ],
    typeGraph2Selected: 1,
    titleGrapht2: {
      1: 'ยอดขายรวม',
      2: 'กำไรจากการขาย',
      3: 'ยอดขายตามหมวดหมู่',
    },
    titleGrapht3: [
      {
        value: 1,
        name: 'สินค้า',
      },
    ],
    typeGraph2: [
      {
        value: 1,
        name: 'ยอดขายรวม',
      },
      {
        value: 2,
        name: 'กำไรจากการขาย',
      },

      // {
      //   value: 3,
      //   name: 'ยอดขายตามหมวดหมู่',
      // },
    ],
    typeGraph3Selected: 1,
    typeGraph3: [
      {
        value: 1,
        name: 'สินค้า',
      },
      {
        value: 2,
        name: 'ประเภทสินค้า',
      },

      // {
      //   value: 3,
      //   name: 'ยอดขายตามหมวดหมู่',
      // },
    ],
    headers: [
      {
        text: '#',
        value: 'index',
        sortable: false,
      },
      {
        text: 'รหัสสินค้า',
        value: 'product_code',
        sortable: false,
      },
      {
        text: 'สินค้า',
        value: 'product_name',
        sortable: false,
      },
      {
        text: 'จำนวน',
        value: 'amount',
        sortable: false,
      },
      {
        text: 'ยอดขาย (บาท)',
        value: 'order_expense',
        sortable: false,
      },
      {
        text: 'ยอดขาย (%)',
        value: 'sell_percent',
        sortable: false,
      },
    ],
    chartData: {
      datasets: [
        {
          label: 'ยอดขายรวม',
          backgroundColor: ['#f87979', '#3E80E1'],
          borderColor: ['#f87979', '#3E80E1'],
          data: [40, 80],
        },
      ],
    },
    chartOptions: {
      responsive: true,
    },
    editedIndex: -1,
    editedItem: {
      numberCustomer: '',
      status: '',
      numberTax: null,
      nameCustomer: null,
      credit: null,
      dateBill: '',
      dateGetMoney: '',
    },
    defaultItem: {
      numberCustomer: '',
      status: '',
      numberTax: null,
      nameCustomer: null,
      credit: null,
      dateBill: '',
      dateGetMoney: '',
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม กลุ่มพนักงาน' : 'แก้ไข กลุ่มพนักงาน'
    },
  },

  watch: {
    serverOptions: {
      handler() {
        this.getSellTable()
      },
    },
    search: {
      handler() {
        this.getSellTable()
      },
    },
    typeGraphSelected: {
      handler() {
        this.getOrderReport()
      },
    },
    typeGraph2Selected: {
      handler() {
        this.getOrderReport()
      },
    },

    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    const date = new Date()
    const day = date.getDate()
    const last_month = date.getMonth() - 2
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    this.date.push(`${year}-${String(last_month).padStart(2, '0')}-${day}`)
    this.date.push(`${year}-${String(month).padStart(2, '0')}-${day}`)
    this.getOrderReport()
    this.getSellTable()
  },

  methods: {
    download_excel(type = 1) {
      if (type == 1) {
        var url = `${process.env.VUE_APP_BASEURL}/exports_total_sell_csv?search=${this.search}&date=${this.date}`
      } else {
        var url = `${process.env.VUE_APP_BASEURL}/exports_table_sell_csv?search=${this.search}&date=${this.date}`
      }
      window.open(url, '_blank', 'noreferrer')
    },
    print() {
      let url = `/print_member_order/${this.member_id}`
      if (this.date) {
        url += `?date=${this.date[0]},${this.date[1]}`
      }
      window.open(url, '_blank', 'noreferrer')
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem)
      } else {
        this.desserts.push(this.editedItem)
      }
      this.close()
    },
    changeDate() {
      this.getOrderReport()
      this.getSellTable()
    },
    getOrderReport() {
      const data = {
        search: this.search,
        type: this.typeGraphSelected,
        type2: this.typeGraph2Selected,
        date: this.date ?? '',
      }
      this.$store.state.isLoading = true
      instance_sell.getReportSell(data)
        .then(res => {
          this.total_sell = res.data.data.total
          this.months = res.data.data.months

          const lbs = []
          const vals = []

          for (const key in res.data.data.months) {
            lbs.push(res.data.data.months[key].month)
            vals.push(res.data.data.months[key].total)
          }

          this.chartData.labels = lbs
          this.chartData.datasets[0].label = this.titleGrapht2[this.typeGraph2Selected]
          this.chartData.datasets[0].data = vals
          this.$store.state.isLoading = false
        })
        .catch(err => {
          this.$store.state.isLoading = false
        })
    },
    getSellTable() {
      const data = {
        search: this.search,
        page: this.serverOptions.page,
        size: this.serverOptions.itemsPerPage,
        sortBy: this.sortBy ?? '',
        sortDesc: this.sortDesc ?? '',
        date: this.date ?? '',
      }
      this.$store.state.isLoading = true
      instance_sell.getReportSellTable(data)
        .then(res => {
          this.orders = res.data.data.data
          this.total = res.data.data.total
          this.serverOptions2.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
          this.serverOptions2.page = res.data.data.current_page

          // this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
          // this.serverOptions.page = res.data.data.current_page
          this.$store.state.isLoading = false
        })
        .catch(err => {
          this.$store.state.isLoading = false
        })
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
